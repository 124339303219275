import { createTranslateLoader } from './shared/utilities/createTranslateLoader';
var ɵ0 = createTranslateLoader;
//
// Sentry.init({
//     dsn: 'https://1c9736d1514449289de33e062f465270@sentry.io/1851883',
//     release: version
// });
//
// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//     constructor() {
//     }
//
//     handleError(error) {
//         const eventId = Sentry.captureException(error.originalError || error);
//         // Sentry.showReportDialog({eventId});
//     }
// }
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
